import React from 'react'
import styled from 'styled-components'
import SocialMediaIcons from '../components/SocialMediaIcons'
import featuredImage from '../images/thank-you-appointment-image.png'

const ThankYouAppointmentPage = () => {
  return (
    <StyledThankYouAppointmentPage>
      <h1>Book an Appointment with a Career Counsellor</h1>
      <img src={featuredImage} alt='' />
      <p>
        <strong>Thank you for submitting your appointment request. We’ll confirm your appointment shortly. We look forward to meeting you!</strong>
      </p>
      <p>
        Don’t forget to find us on Facebook, Instagram or YouTube too!
      </p>
      <SocialMediaIcons />
    </StyledThankYouAppointmentPage>
  )
}

const StyledThankYouAppointmentPage = styled.main`
  display: flow-root;
  max-width: 1500px;
  margin: auto;
  padding: 6rem 1rem 4rem 1rem;

  h1 {
    margin-bottom: 2rem;
  }

  img {
    /* display: inline; */
    float: left;
    margin: 0 2rem 1rem 0;
    max-width: 100%;
  }

  p {
    margin-bottom: 2rem;
  }

  @media (max-width: 480px) {
    padding: 2rem 1rem;
  }
`

export default ThankYouAppointmentPage
